import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Header from '../Header';
import Navigation from '../Navigation';
import Footer from '../Footer';
import CustomCursor from '../CustomCursor';
import AwwwardsBadge from '../AwwwardsBadge';

import '../../assets/scss/main.scss';

const Layout = ({
  children,
  headerTheme,
  footerPromo,
  footerPromoTheme,
  mainClass,
}) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      contact: contentfulContactPage {
        awwwardsBanner
      }
    }
  `);

  const {
    contact: { awwwardsBanner },
  } = data;

  return (
    <>
      <Header theme={headerTheme} />
      <Navigation theme={headerTheme} />
      <main className={mainClass}>{children}</main>
      <Footer promo={footerPromo} theme={footerPromoTheme} />
      <CustomCursor />
      {awwwardsBanner && <AwwwardsBadge />}
    </>
  );
};

export default Layout;

export default [
  {
    link: '/work',
    text: 'Work',
  },
  {
    link: '/services',
    text: 'Services',
  },
  {
    link: '/people',
    text: 'People',
  },
  {
    link: '/contact',
    text: 'Contact',
  },
];

import React, { createRef, useEffect, memo } from 'react';
import classnames from 'classnames';
import lottie from 'lottie-web';

import animationLight from '../../data/logos/light.json';
import animationDark from '../../data/logos/dark.json';

const AnimatedLogo = ({ variant, theme }) => {
  let animationContainer = createRef();

  let animationData;
  switch (theme) {
    case 'dark':
      animationData = animationDark;
      break;
    case 'color':
      animationData = animationLight;
      break;
    case 'white':
      animationData = animationLight;
      break;
    default:
      animationData = animationLight;
      break;
  }

  useEffect(
    (variant) => {
      const anim = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData,
      });
      return () => anim.destroy();
    },
    [animationContainer, animationData]
  );

  return (
    <div
      className={classnames('animated-logo', {
        [`animated-logo--${variant}`]: variant,
      })}
      ref={animationContainer}
    />
  );
};

export default memo(AnimatedLogo);

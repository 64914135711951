import React, { useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import classnames from 'classnames';

import FadeOnScroll from '../FadeOnScroll';

import Instagram from '../../assets/images/site/icon_instagram.svg';
import LinkedIn from '../../assets/images/site/icon_linkedin.svg';

import pages from '../../data/pages';

const Header = ({ theme }) => {
  const navigationTimeout = 200;

  const [isLoaded, setIsLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);

  const data = useStaticQuery(graphql`
    query NavigationPanelQuery {
      contact: contentfulContactPage {
        email
        telephone
        linkedin
        instagram
      }
    }
  `);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 400);
  }, []);

  const {
    contact: { email, telephone, linkedin, instagram },
  } = data;

  const handleClick = (e) => {
    if (open) {
      handleClose();
    } else {
      handleOpen();
    }
    e.preventDefault();
  };

  const handleCloseClick = (e) => {
    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
    if (document) {
      document.querySelector('body').classList.add('no-scroll');
    }

    setTimeout(() => {
      setOpened(true);
    }, navigationTimeout / 2);
  };

  const handleClose = () => {
    setOpened(false);

    setTimeout(() => {
      setOpen(false);
      if (document) {
        document.querySelector('body').classList.remove('no-scroll');
      }
    }, navigationTimeout * 1.5);
  };

  return (
    <>
      <nav
        className={classnames('navigation', {
          'is-open': open,
          'is-opened': opened,
        })}
        id="navigation"
      >
        <a
          href="#navigation"
          className={classnames('navigation__trigger', 'is-enhanced', {
            'navigation__trigger--loaded': isLoaded,
            'is-active': open,
            [`navigation__trigger--${theme}`]: theme,
          })}
          onClick={(e) => handleClick(e)}
          aria-label="Open menu"
        >
          <FadeOnScroll>
            <span className="navigation__trigger__enhanced-wrap">
              <i className="navigation__trigger__line1"></i>
              <i className="navigation__trigger__line2"></i>
            </span>
          </FadeOnScroll>
        </a>
        <div className="navigation__panel">
          <Link
            to="/"
            className={classnames(
              'header__logo',
              'header__logo--grey',
              'navigation__logo',
              {
                'navigation__logo--show': opened,
              }
            )}
          >
            Wreel Agency
          </Link>
          <ul className="navigation__list">
            {pages.map(({ link, text }, i) => {
              return (
                <li
                  className="navigation__list-item navigation__list-item--navigation"
                  key={`navigationItems-${i}`}
                >
                  <Link
                    activeClassName="is-active"
                    to={`${link}/`}
                    onClick={(e) => handleCloseClick(e)}
                  >
                    {text}
                  </Link>
                </li>
              );
            })}
            <li className="navigation__list-item navigation__list-item--spacer navigation__list-item--contact">
              E. <a href={`mailto:${email}`}>{email}</a>
            </li>
            <li className="navigation__list-item navigation__list-item--contact">
              T. <a href={`tel:${telephone}`}>{telephone}</a>
            </li>
            <li className="navigation__list-item navigation__list-item--social">
              <a
                href={linkedin}
                className="navigation__social-link"
                aria-label="Find us in LinkedIn"
              >
                <LinkedIn />
              </a>
              <a
                href={instagram}
                className="navigation__social-link"
                aria-label="Find us in Instagram"
              >
                <Instagram />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;

import React, { useState, memo } from 'react';
import { useInView } from 'react-hook-inview';
import { useScrollDirection } from 'react-use-scroll-direction';

import classnames from 'classnames';

const FadeIntoView = ({ noFade, children, callback, as, extraClass }) => {
  const [inView, setInView] = useState(false);

  let ref;

  [ref] = useInView({
    threshold: 0.1,
    unobserveOnEnter: true,
    onEnter: () => {
      setInView(true);
      if (callback) {
        callback();
      }
    },
  });

  const El = as ? as : 'div';

  const { isScrollingUp } = useScrollDirection();

  return (
    <El
      className={classnames('fade-into-view', {
        'fade-into-view--force': isScrollingUp,
        'fade-into-view--in-view': inView || noFade,
        [extraClass]: extraClass,
      })}
      ref={ref}
    >
      {children}
    </El>
  );
};

export default memo(FadeIntoView);

import React from 'react';
import { Link } from 'gatsby';

import ArrowIcon from '../../assets/images/site/arrow-right.svg';

import FadeIntoView from '../FadeIntoView';

import classnames from 'classnames';

const LinkButton = ({ theme, link, text, extraClass, hasArrow }) => {
  const trailingSlash = link.slice(-1) === '/' ? '' : '/';
  const strippedLink = link
    .replace('https://', '')
    .replace('http://', '')
    .replace('www.', '')
    .replace('wreel.agency', '')
    .replace('wreelcollective.com', '');

  return (
    <FadeIntoView>
      <section
        className={classnames('link-button', {
          [`link-button--${theme}`]: theme,
          [extraClass]: extraClass,
        })}
      >
        <Link
          className="link-button__link"
          to={`${strippedLink}${trailingSlash}`}
        >
          <span className="link-button__link-inner">
            <span className="link-button__link-span">
              {text}
              {hasArrow ? <ArrowIcon className="link-button__arrow" /> : ''}
            </span>
          </span>
        </Link>
      </section>
    </FadeIntoView>
  );
};

export default LinkButton;

import React, { memo } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import classnames from 'classnames';

import FadeUpIntoView from '../FadeUpIntoView';
import LinkButton from '../LinkButton';

import Instagram from '../../assets/images/site/icon_instagram.svg';
import LinkedIn from '../../assets/images/site/icon_linkedin.svg';
import Email from '../../assets/images/site/icon_email.svg';
import Phone from '../../assets/images/site/icon_phone.svg';

import Logo from '../../assets/images/site/logo.svg';

const Footer = ({ promo, theme }) => {
  const data = useStaticQuery(graphql`
    query FooterPanelQuery {
      contact: contentfulContactPage {
        email
        telephone
        address
        linkedin
        instagram
        partners {
          label
          url
        }
      }
    }
  `);

  const {
    contact: { email, telephone, address, linkedin, instagram, partners },
  } = data;

  const buttonTheme = !theme || theme === 'light' ? 'dark' : 'light';

  return (
    <>
      {promo && promo !== 'none' && (
        <section
          className={classnames('panel', 'promo-panel', {
            'promo-panel--light': theme === 'light',
          })}
        >
          <div>
            {(() => {
              switch (promo) {
                case 'work with us':
                  return (
                    <>
                      <h1 className="promo-panel__title">
                        <FadeUpIntoView>Like to work with us?</FadeUpIntoView>
                      </h1>

                      <LinkButton
                        text="Get in touch"
                        link="/contact/"
                        theme={buttonTheme}
                        hasArrow={true}
                      />
                    </>
                  );
                case 'keen to chat':
                  return (
                    <>
                      <h1 className="promo-panel__title">
                        <FadeUpIntoView>Keen to chat?</FadeUpIntoView>
                      </h1>
                      <LinkButton
                        text="Get in touch"
                        link="/contact/"
                        theme={buttonTheme}
                        hasArrow={true}
                      />
                    </>
                  );
                default:
                  return (
                    <>
                      <h1 className="promo-panel__title">
                        <FadeUpIntoView>Seen the work?</FadeUpIntoView>
                      </h1>

                      <LinkButton
                        text="Take a look"
                        link="/work/"
                        theme={buttonTheme}
                        hasArrow={true}
                      />
                    </>
                  );
              }
            })()}
          </div>
        </section>
      )}
      <footer className="footer panel">
        <section className="footer__main">
          <div className="footer__contact">
            <h2 className="footer__title footer__title--first">Say hello</h2>
            <address className="footer__address">{address}</address>

            <ul>
              <li className="footer__link-item footer__link-item--contact">
                <a href={`tel:${telephone}`}>
                  <Phone className="footer__contact-icon" />
                  {telephone}
                </a>
              </li>
              <li className="footer__link-item footer__link-item--contact">
                <a href={`mailto:${email}`}>
                  <Email className="footer__contact-icon" />
                  {email}
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h2 className="footer__title">Our partners</h2>
            <ul>
              {partners.map(({ label, url }, i) => (
                <li className="footer__link-item" key={`footerLink-${i}`}>
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    {label}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h2 className="footer__title">The socials</h2>
            <ul className="footer__social">
              <li className="footer__social-item">
                <a
                  href={linkedin}
                  className="footer__social-icon"
                  aria-label="Find us in LinkedIn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedIn />
                </a>
              </li>
              <li className="footer__social-item">
                <a
                  href={instagram}
                  className="footer__social-icon"
                  aria-label="Find us in Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Instagram />
                </a>
              </li>
            </ul>
          </div>
          <p className="footer__copyright">
            &copy; {new Date().getFullYear()} Wreel Agency Ltd. <br />
            Registered in England No. 09143128
          </p>
        </section>

        <section className="footer__base">
          <div>
            <Link to="/" className="footer__logo-link">
              <Logo className="footer__logo" />
            </Link>
          </div>
        </section>
      </footer>
    </>
  );
};

export default memo(Footer);

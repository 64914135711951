import React, { memo, useEffect, useState } from 'react';
import classnames from 'classnames';

const FadeOnScroll = ({ children, as, extraClass, slow }) => {
  const [opacity, setOpacity] = useState(100);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      calculateOpacity();
      window.addEventListener('scroll', calculateOpacity);

      return () => window.removeEventListener('scroll', calculateOpacity);
    }
  });

  const calculateOpacity = () => {
    const scrollSpeed = slow ? 0.3 : 0.8;

    const { scrollY } = window;
    const scrollMod = scrollY * scrollSpeed;
    const scrollOpacity = 100 - scrollMod;

    setOpacity(Math.max(0, scrollOpacity));
  };

  const El = as ? as : 'div';

  return (
    <El
      className={classnames('fade-on-scroll', {
        [extraClass]: extraClass,
      })}
      style={{
        opacity: `${opacity}%`,
      }}
    >
      {children}
    </El>
  );
};

export default memo(FadeOnScroll);

import React, { memo, useState } from 'react';
import { useInView } from 'react-hook-inview';
import { useScrollDirection } from 'react-use-scroll-direction';

import classnames from 'classnames';

const FadeIntoView = ({
  noFade,
  children,
  callback,
  as,
  extraClass,
  delayBy,
}) => {
  const [inView, setInView] = useState(false);

  let ref;

  [ref] = useInView({
    threshold: 0.1,
    unobserveOnEnter: true,
    onEnter: () => {
      setInView(true);
      if (callback) {
        callback();
      }
    },
  });

  const El = as ? as : 'div';

  const { isScrollingUp } = useScrollDirection();

  return (
    <El
      className={classnames('fade-up-into-view', {
        'fade-up-into-view--force': isScrollingUp,
        'fade-up-into-view--in-view': inView || noFade,
        [`fade-up-into-view--delay-${delayBy}`]: delayBy,
        [extraClass]: extraClass,
      })}
      ref={ref}
    >
      {children}
    </El>
  );
};

export default memo(FadeIntoView);

import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

import AnimatedLogo from '../AnimatedLogo';
import FadeOnScroll from '../FadeOnScroll';

const Header = ({ theme }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 10);
  }, []);

  return (
    <header className="header">
      <FadeOnScroll>
        <h1
          className={classnames('header__logo-wrap', {
            'header__logo-wrap--loaded': isLoaded,
          })}
        >
          <Link to="/" aria-label="WReel Logo: to home page">
            <AnimatedLogo variant="header" theme={theme} />
          </Link>
        </h1>
      </FadeOnScroll>
    </header>
  );
};

export default Header;

import React, { useEffect, useState } from 'react';

import classnames from 'classnames';

import hasMouse from '../../utils/hasMouse';

const CustomCursor = () => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const [hovered, setHovered] = useState(false);
  const [click, setClick] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [text, setText] = useState('');
  const [darkTheme, setDarkTheme] = useState(false);

  useEffect(() => {
    if (hasMouse()) {
      if (document) {
        document.querySelector('body').classList.add('cc');
      }

      const mouseMoveHandler = (e) => {
        const { clientX, clientY, target } = e;
        const customText = target.closest('[data-cursor-text]');
        const darkOverride = target.closest('[data-cursor-dark]');

        setHidden(false);

        setMousePosition({ x: clientX, y: clientY });
        setHovered(target.closest('a') || target.closest('button'));
        setText(
          customText
            ? target
                .closest('[data-cursor-text]')
                .getAttribute('data-cursor-text')
            : ''
        );
        setDarkTheme(!!darkOverride);
      };

      const mouseInHandler = () => setHidden(false);
      const mouseOutHandler = () => setHidden(true);
      const mouseDownHandler = () => setClick(true);
      const mouseUpHandler = () => setClick(false);

      document.addEventListener('wheel', mouseMoveHandler);
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseenter', mouseInHandler);
      document.addEventListener('mouseleave', mouseOutHandler);
      document.addEventListener('mousedown', mouseDownHandler);
      document.addEventListener('mouseup', mouseUpHandler);

      return () => {
        document.removeEventListener('wheel', mouseMoveHandler);
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseenter', mouseInHandler);
        document.removeEventListener('mouseleave', mouseOutHandler);
        document.removeEventListener('mousedown', mouseDownHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };
    }
  }, []);

  if (!mousePosition.x || !mousePosition.y || !hasMouse()) {
    return null;
  }

  const { x, y } = mousePosition;

  return (
    <div
      style={{
        transform: `translate(calc(-50% + ${x}px), calc(-50% + ${y}px))`,
      }}
      className={classnames('custom-cursor', {
        'custom-cursor--hover': hovered,
        'custom-cursor--click': click,
        'custom-cursor--hidden': hidden,
        'custom-cursor--dark-hover': darkTheme,
        'custom-cursor--text-hover': text.length > 1,
      })}
    >
      {text.length > 1 && text}
    </div>
  );
};

export default CustomCursor;

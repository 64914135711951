import React from 'react';
import { useLocation } from '@reach/router';

import { Helmet } from 'react-helmet';

const SEO = ({ title, description, themeColor, noIndex }) => {
  const pageTitle = `${title} | Wreel Agency`;

  const { pathname } = useLocation();
  const canonical = `https://wreel.agency/${pathname}`;

  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={pageTitle}
      meta={[
        { name: 'description', content: description },
        { property: 'og:title', content: pageTitle },
        { property: 'og:description', content: description },
        { property: 'og:url', content: canonical },
        {
          property: 'og:image',
          content: 'https://wreel.agency/share-thumbnail.png',
        },
        {
          name: 'robots',
          content:
            process.env.GATSBY_IS_DEV === 'true' || noIndex
              ? 'noindex, follow, noarchive'
              : 'index, follow, noarchive, max-video-preview:0, max-snippet:-1',
        },
        {
          name: 'theme-color',
          content: themeColor ? themeColor : 'rgb(11, 11, 11)',
        },
      ]}
    >
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          name: pageTitle,
          description: description,
          url: canonical,
          primaryImageOfPage: [
            {
              '@type': 'ImageObject',
              url: 'https://wreel.agency/wreel_thumbnail_1x1.png',
              caption: 'Wreel Agency logo (square)',
              width: 1200,
              height: 1200,
            },
            {
              '@type': 'ImageObject',
              url: 'https://wreel.agency/wreel_thumbnail_16x9.png',
              caption: 'Wreel Agency logo (16:9)',
              width: 1200,
              height: 630,
            },
            {
              '@type': 'ImageObject',
              url: 'https://wreel.agency/wreel_thumbnail_4x3.png',
              caption: 'Wreel Agency logo (4:3)',
              width: 1200,
              height: 900,
            },
          ],
          image: [
            {
              '@type': 'ImageObject',
              url: 'https://wreel.agency/wreel_thumbnail_1x1.png',
              caption: 'Wreel Agency logo (square)',
              width: 1200,
              height: 1200,
            },
            {
              '@type': 'ImageObject',
              url: 'https://wreel.agency/wreel_thumbnail_16x9.png',
              caption: 'Wreel Agency logo (16:9)',
              width: 1200,
              height: 630,
            },
            {
              '@type': 'ImageObject',
              url: 'https://wreel.agency/wreel_thumbnail_4x3.png',
              caption: 'Wreel Agency logo (4:3)',
              width: 1200,
              height: 900,
            },
          ],
        })}
      </script>
    </Helmet>
  );
};

export default SEO;
